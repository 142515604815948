<template>
  <div class="container-transaction">
    <div id="payment" class="container-transaction-vertical-center">
      <div v-cus-loading="loading.main" class="transaction-summary">
        <table
          class="wrapper"
          width="100%"
          height="100%"
          cellpadding="0"
          cellspacing="0"
          role="presentation"
        >
          <tr>
            <td align="center" width="100%">
              <table
                class="sm-w-full table-main"
                width="600"
                cellpadding="0"
                cellspacing="0"
                role="presentation"
              >
                <tr>
                  <td
                    class="sm-inline-block bordered-shadow"
                    style="display: none"
                    colspan="2"
                  >
                  </td>
                </tr>
                <tr>
                  <td class="sm-hidden print-hidden title-message" width="160">
                  </td>
                  <td
                    class="sm-p-20 bordered-shadow content"
                    align="left"
                    bgcolor="#ffffff"
                    valign="top"
                  >
                    <table
                      class="sm-p-20 content header-images"
                      width="100%"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                    >
                      <tr>
                        <td class="sm-inline-block" align="center">
                          <img
                            style="border: 0; display: block"
                            src="@/assets/img/svg/logo-aliaddo.svg"
                            height="60"
                          />
                        </td>
                      </tr>
                    </table>
                    <table
                      width="100%"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                    >
                      <tr>
                        <td width="80%">
                          <h1
                            :class="`text-${toKebabCase(model.status.status)}`"
                            class="sm-text-lg text-title"
                          >
                            {{ $tc('transaction') }}
                            {{ $tc(toCamelCase(model.status.status)) }}
                          </h1>
                          <p
                            :class="`text-${toKebabCase(model.status.status)}`"
                            class="sm-text-xs text-title-message"
                          >
                            {{
                              $tc(
                                `transaction${toPascalCase(
                                  model.status.status,
                                )}Message`,
                              )
                            }}
                          </p>
                        </td>
                        <td
                          class="print-hidden"
                          style="text-align: right"
                          width="20%"
                          align="left"
                        >
                          <el-button
                            type="text"
                            circle
                            size="medium"
                            @click="onPrint('payment')"
                          >
                            <span
                              :class="`text-${toKebabCase(
                                model.status.status,
                              )}`"
                            >
                              <i
                                class="fad fa-print fa-2x"
                                :title="$tc('print')"
                              ></i>
                            </span>
                          </el-button>
                        </td>
                      </tr>
                    </table>
                    <div style="line-height: 32px">
&zwnj;
</div>
                    <table
                      class="sm-leading-32 sub-content"
                      width="100%"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                    >
                      <tbody>
                        <tr>
                          <td class="responsive text-subtitle" width="50%">
                            {{ $tc('companyName') }}
                          </td>
                          <td
                            class="responsive text-content"
                            width="auto"
                            align="left"
                          >
                            {{ $tc('aliaddo') }}
                          </td>
                        </tr>
                        <tr>
                          <td class="responsive text-subtitle" width="50%">
                            {{ $tc('nit') }}
                          </td>
                          <td
                            class="responsive text-content"
                            width="auto"
                            align="left"
                          >
                            {{ $tc('aliaddoNit') }}
                          </td>
                        </tr>
                        <tr>
                          <td class="responsive text-subtitle" width="50%">
                            {{ $tc('ip') }}
                          </td>
                          <td
                            class="responsive text-content"
                            width="auto"
                            align="left"
                          >
                            {{ model.request.ipAddress }}
                          </td>
                        </tr>
                        <tr v-if="model.request.payer">
                          <td class="responsive text-subtitle" width="50%">
                            {{ $tc('customer') }}
                          </td>
                          <td
                            class="responsive text-content"
                            width="auto"
                            align="left"
                          >
                            {{ model.request.payer.name }}
                            {{ model.request.payer.surname }}
                          </td>
                        </tr>
                        <tr v-if="model.payment">
                          <td class="responsive text-subtitle" width="50%">
                            {{ $tc('reference') }}
                          </td>
                          <td
                            class="responsive text-content"
                            width="auto"
                            align="left"
                          >
                            {{ (model.payment || {}).reference }}
                          </td>
                        </tr>
                        <tr>
                          <td class="responsive text-subtitle" width="50%">
                            {{ $tc('status') }}
                          </td>
                          <td
                            class="responsive text-content"
                            width="auto"
                            align="left"
                          >
                            {{ $tc(model.status.status | toCamelCase) }}
                          </td>
                        </tr>
                        <tr>
                          <td class="responsive text-subtitle" width="50%">
                            {{ $tc('message') }}
                          </td>
                          <td
                            class="responsive text-content"
                            width="auto"
                            align="left"
                          >
                            ({{ model.status.reason }})-{{
                              model.status.message
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="responsive text-subtitle" width="50%">
                            {{ $tc('description') }}
                          </td>
                          <td
                            class="responsive text-content"
                            width="auto"
                            align="left"
                          >
                            {{ model.request.payment.description }}
                          </td>
                        </tr>
                        <tr class="responsive text">
                          <td class="responsive text-subtitle" width="50%">
                            {{ $tc('iva', 1) }}
                          </td>
                          <td
                            class="responsive text-content"
                            width="auto"
                            align="left"
                          >
                            {{
                              model.placeToPay.placeToPayIvaValue
                                | formatToCurrencyWithIsoCode
                            }}
                          </td>
                        </tr>
                        <tr class="responsive text">
                          <td class="responsive text-subtitle" width="50%">
                            {{ $tc('iva', 1) }} ({{ $tc('base', 2) }})
                          </td>
                          <td
                            class="responsive text-content"
                            width="auto"
                            align="left"
                          >
                            {{
                              model.placeToPay.placeToPayIvaBase
                                | formatToCurrencyWithIsoCode
                            }}
                          </td>
                        </tr>
                        <tr v-if="model.payment">
                          <td
                            :class="`text-${toKebabCase(model.status.status)}`"
                            class="responsive text-content"
                            width="50%"
                          >
                            {{ $tc('cus') }}
                          </td>
                          <td
                            :class="`text-${toKebabCase(model.status.status)}`"
                            class="responsive text-content"
                            width="50%"
                            align="left"
                          >
                            {{ (model.payment || {}).authorization }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            :class="`text-${toKebabCase(model.status.status)}`"
                            class="responsive text-content"
                            width="50%"
                          >
                            {{ $tc('date') }}
                          </td>
                          <td
                            :class="`text-${toKebabCase(model.status.status)}`"
                            class="responsive text-content"
                            width="50%"
                            align="left"
                          >
                            {{ model.status.date | formatToDateTime }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      class="sm-leading-32 sub-content"
                      width="100%"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                    >
                      <tbody></tbody>
                    </table>
                    <table
                      width="100%"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                    >
                      <tr>
                        <td style="padding-top: 18px">
                          <div
                            :class="`background-${toCamelCase(
                              model.status.status,
                            )}`"
                            class="line-separator"
                            style="line-height: 10px"
                          >
                            &zwnj;
                          </div>
                        </td>
                      </tr>
                    </table>
                    <table
                      v-if="model.payment"
                      class="sm-leading-32 sub-content"
                      width="100%"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                    >
                      <tbody>
                        <tr class="text-footer">
                          <td
                            :class="`text-${toKebabCase(model.status.status)}`"
                            class="responsive text-footer-content"
                            width="50%"
                          >
                            {{ $tc('value') }}
                          </td>
                          <td
                            :class="`text-${toKebabCase(model.status.status)}`"
                            class="responsive text-footer-content"
                            width="50%"
                            align="right"
                          >
                            {{
                              model.placeToPay.placeToPayAmountTotal
                                | formatToCurrencyWithIsoCode
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      v-else
                      class="sm-leading-32 sub-content"
                      width="100%"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                    >
                      <tbody>
                        <tr class="text-footer">
                          <td
                            :class="`text-${toKebabCase(model.status.status)}`"
                            class="responsive text-footer-content"
                            width="50%"
                          >
                            {{ $tc('value') }}
                          </td>
                          <td
                            :class="`text-${toKebabCase(model.status.status)}`"
                            class="responsive text-footer-content"
                            width="50%"
                            align="right"
                          >
                            {{
                              model.placeToPay.placeToPayAmountTotal
                                | formatToCurrencyWithIsoCode
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      width="100%"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                    >
                      <tr>
                        <td style="padding-top: 18px; padding-bottom: 18px">
                          <div
                            :class="`background-${toCamelCase(
                              model.status.status,
                            )}`"
                            class="line-separator"
                            style="line-height: 15px"
                          >
                            &zwnj;
                          </div>
                        </td>
                      </tr>
                    </table>
                    <table
                      v-if="model.payment"
                      width="100%"
                      class="sm-w-full table-main footer-images"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                    >
                      <tr>
                        <td align="center" class="responsive text-content">
                          <el-button
                            size="mini"
                            type="text"
                            class="btn btn-payment-button"
                            @click="onReturnService()"
                          >
                            {{ $tc('returnService') }}
                          </el-button>
                        </td>
                      </tr>
                    </table>
                    <table
                      v-if="
                        model.status.status !== 'APPROVED' &&
                        model.status.status !== 'PENDING' &&
                        model.status.status !== 'NBXRPENDING' &&
                        model.urlRetryPayment
                      "
                      width="100%"
                      class="sm-w-full table-main footer-images"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                    >
                      <tr>
                        <td align="center" class="responsive text-content">
                          <el-button
                            size="mini"
                            type="text"
                            class="btn btn-payment-button"
                            @click="onRetryPayment()"
                          >
                            {{ $tc('retry') }}
                          </el-button>
                        </td>
                      </tr>
                    </table>
                    <table
                      class="sm-w-full table-main footer-images"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                    >
                      <tr>
                        <td
                          class="responsive text-content"
                          align="left"
                          width="50%"
                        >
                          <img
                            src="https://hub.aliaddo.com/img/place_to_pay/MASTERCARD.png"
                            height="25"
                            alt="Mastercard"
                          />
                          <img
                            src="https://hub.aliaddo.com/img/place_to_pay/PSE.png"
                            height="25"
                            alt="Pse"
                          />
                          <img
                            src="https://hub.aliaddo.com/img/place_to_pay/VISA.png"
                            height="25"
                            alt="Visa"
                          />
                        </td>
                        <td class="responsive text-content">
                          <address>
                            <a href="mailto:#">facturacion@aliaddo.com</a>
                          </address>
                        </td>
                        <td
                          class="responsive text-content"
                          align="right"
                          width="50%"
                        >
                          <a
                            href="https://www.placetopay.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="https://hub.aliaddo.com/img/place_to_pay/Logo_PlacetoPay.png"
                              height="25"
                            />
                          </a>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>

/* vendors
-------------------------------------------------- */
import Vue from 'vue'
import VueHtmlToPaper from 'vue-html-to-paper'

/* End of vendors
-------------------------------------------------- */
/* nbxr
-------------------------------------------------- */
import { mapFilters } from '@/filters/map-filters'

/* End of nbxr
-------------------------------------------------- */

// #region  /*----------  VueHtmlToPaper  ----------*/

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://unpkg.com/element-ui/lib/theme-chalk/index.css',
    '/css/transaction-result.css',
  ],
}

Vue.use(VueHtmlToPaper, options)

// #endregion

export default {
  name: 'PlaceToPayResult',
  data() {
    return {
      loading: {
        main: false,
      },
      model: {
        payment: {
          amount: {
            factor: 1,
            from: {
              currency: 'COP',
              total: '',
            },
            to: {
              currency: 'COP',
              total: '',
            },
          },
          reference: '',
        },
        placeToPay: {
          placeToPayIvaBase: '',
          placeToPayIvaValue: '',
          placeToPayRecurrent: {},
          placeToPayAmountTotal: 0,
        },
        request: {
          ipAddress: '',
          payer: {
            address: '',
            company: '',
            document: '',
            documentType: '',
            email: '',
            mobile: '',
            name: '',
            surname: '',
          },
          payment: {},
        },
        status: {
          date: '',
          message: '',
          reason: '',
          statusName: '',
        },
      },
    }
  },

  mounted() {
    this.processPayment()
  },
  methods: {
    ...mapFilters(['toCamelCase', 'toKebabCase', 'toPascalCase']),
    onPrint(idTag) {
      this.$htmlToPaper(idTag)
    },
    onReturnService() {
      const _this = this
      window.location.replace(
        `https://${_this.model.service.serviceDomain}/?refresh`,
      )
    },
    onRetryPayment() {
      const _this = this
      window.location.replace(_this.model.urlRetryPayment)
    },
    getImageStatus(status) {
      if (['APPROVED', 'APPROVED_PARTIAL', 'OK'].includes(status)) {
        return '/img/transactions/approved.svg'
      }
      if (['PENDING', 'PENDING_VALIDATION', 'NBXRPENDING'].includes(status)) {
        return '/img/transactions/pending.svg'
      }

      return '/img/transactions/rejected.svg'
    },
    processPayment() {
      const _this = this
      _this.loading.main = true
      _this.$http
        .patch(
          `payments/placeToPay/${_this.$route.params.placeToPayReference}/payment/success`,
        )
        .then(response => {
          _this.model = response.body
          console.log('%c⧭', 'color: #00b300', _this.model)

          // _this.model.collect = response.body.collect
        })
        .finally(() => {
          _this.loading.main = false

          return ''
        })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/transactions/transaction-result.scss';
</style>
